<template>
  <div>
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "App",
  components: { FadeTransition },
  metaInfo: {
    title: "Ayun!",
    titleTemplate: "%s | Asset Tracker",
    meta: [
      { charset: "utf-8" },
      { name: "author", content: "TAWItech" },
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.5, user-scalable=1, shrink-to-fit=no",
      },
      {
        vmid: "description",
        name: "description",
        content:
          'Application-based asset monitoring system that tracks returnable assets in your supply chain. It uses QR codes to determine the "last touch" in the system.',
      },
    ],
  },
};
</script>

<style lang="scss" src="./App.scss" />
