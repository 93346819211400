const scroll = {
    state: {
        isVideoPlaying: false
    },
    getters: {
        isVideoPlaying(state) {
            return state.isVideoPlaying
        }
    },
    mutations: {
        SET_VIDEO_PLAYING(state, payload) {
            state.isVideoPlaying = payload;
        }
    },
    actions: {
        playVideoAction({commit}, payload) {
            commit('SET_VIDEO_PLAYING', payload);
        }
    }
}

export default scroll;