import moment from 'moment-timezone'

export const dateFormat = (date) => {
  if (date) {
    var format = 'MMMM DD, YYYY'
    return moment(date).tz('Asia/Manila').format(format)
  }
  return ''
}

export const dateTimeFormat = (date) => {
  if (date) {
    var format = 'MMMM DD, YYYY, hh:mm A'
    return moment(date).tz('Asia/Manila').format(format);
  }
  return '';
}

export const shortDateFormat = (date) => {
  return moment(date).format('L');
}