import Vue from "vue";
import Router from "vue-router";
import { auth } from '../config/firebase'
import routes from './routes'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    const position = {};
    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;

      return new Promise(resolve => {
        this.app.$root.$once('scrollBeforeEnter', () => {
          resolve(position);
        });
      });
    } else if (to.hash)
      return { selector: to.hash };
    else
      return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser)
    next('/login');
  else if (currentUser)
    next();
  else 
    next();
});

// For Google Analytics
router.afterEach((to, from) => {
  Vue.$gtag.pageview({ page_path: to.fullPath });
});

export default router;