import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store';
import Argon from "./plugins/argon-kit";
import './registerServiceWorker';
import { auth } from './config/firebase';
import Toaster from 'v-toaster';
import { dateFormat, shortDateFormat, dateTimeFormat } from './utils/dateUtils';
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import { GA_TRACKING_ID } from './config/constants';
import VueIziToast from 'vue-izitoast';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import 'v-toaster/dist/v-toaster.css';
import 'izitoast/dist/css/iziToast.min.css'

import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.array.sort';
import 'core-js/modules/es.number.constructor';
import 'core-js/modules/es.object.keys';
// import 'core-js/modules/es.regexp.replace';
// import 'core-js/modules/es.regexp.search';


extend('required', { ...required, message: '{_field_} is required' });;

Vue.config.productionTip = false;

Vue.use(Argon);
Vue.use(Toaster, { timeout: 5000 });
Vue.use(VueIziToast, { position: 'bottomRight' });
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueMeta);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 0,
  easing: "ease-out",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: function (element) { location.hash = element.id; },
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueGtag, {
  config: { id: GA_TRACKING_ID.prod }
});

jQuery.extend(true, jQuery.fn.datetimepicker, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

Vue.filter('dateFormat', function (value) {
  if (!value) return '';
  return dateFormat(value);
});

Vue.filter('dateTimeFormat', function (value) {
  if (!value) return '';
  return dateTimeFormat(value);
});

Vue.filter('shortDateFormat', function (value) {
  if (!value) return '';
  return shortDateFormat(value);
});

Vue.filter('regularDateFormat', function (value) {
  if (!value) return '';
  return dateFormat(value);
});

let app = '';
auth.onAuthStateChanged(user => {
  if (user)
    store.dispatch('user/setUserDetailsAction', user);

  if (!app)
    app = new Vue({
      store,
      router,
      render: h => h(App),
    }).$mount('#app')
})